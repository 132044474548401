import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FormInputProps } from '@microservices/wiskey-react-components'
import { DoneAll } from '@mui/icons-material'
import { MenuItem, Typography } from '@mui/material'

import { getObjectValueName } from '@helpers'
import { FIELD_VALUE_TYPE, GENERATOR_INPUT_TYPE, SEARCH_FIELD_TYPE } from '@constants'
import { AutocompleteOption, GETCriteriaType, ObjectFieldDTO, ViewShortDTO } from '@types'

type ValueInputsOptions = {
  objectFields: ObjectFieldDTO[] | undefined
  linkedObjectFields: ObjectFieldDTO[] | undefined
  valueNameInputName: string
  defaultPlaceholderInputName: string
  groupRelatedInputName: string
  placeholderValueInputName: string
  fieldTypeInputName: string
  objectValueInputName: string
  watchedObjectTitle: string
  watchedValueName: AutocompleteOption | null
  placeholderStartInputName: string
  placeholderEndInputName: string
  watchedDefaultplaceholder: boolean
  dropDownListCodeInputName: string
  dropDownWindowInputName: string
  dropdownEntities: ViewShortDTO[]
  valueType?: FIELD_VALUE_TYPE
  asCheckboxInputName: string
  asDurationInputName: string
  valueTypeInputName: string
  addedFiltersByObject: GETCriteriaType[]
}

type AutocompleteOptionValueType = AutocompleteOption & { enumLabel?: string }

export const useValueInputs = ({
  objectFields,
  linkedObjectFields,
  valueNameInputName,
  fieldTypeInputName,
  objectValueInputName,
  watchedObjectTitle,
  watchedValueName,
  defaultPlaceholderInputName,
  groupRelatedInputName,
  placeholderValueInputName,
  placeholderStartInputName,
  placeholderEndInputName,
  watchedDefaultplaceholder,
  dropDownListCodeInputName,
  dropDownWindowInputName,
  asCheckboxInputName,
  asDurationInputName,
  dropdownEntities,
  valueTypeInputName,
  valueType,
  addedFiltersByObject,
}: ValueInputsOptions) => {
  const { t } = useTranslation()

  const dropdownEntityOptions: AutocompleteOption[] = useMemo(
    () =>
      dropdownEntities.map(entity => ({
        id: entity.code,
        label: entity.title,
      })) ?? [],
    [dropdownEntities]
  )

  const valueInput = useMemo((): FormInputProps => {
    const options = [...(objectFields || [])].map(field => ({
      // Если value, которое выбирает пользователь, ссылочное, то для дальнейших запросов будет
      // необходим код объекта, на который это value ссылается. Пример:
      // value = airline_ref. Для запроса групп нужен сам объект tb_airlines. Он как раз таки и
      // возьмется из field.model.
      id: field.model ?? field.id,
      label: field.name,
      labelWithPostfix: getObjectValueName(field.name, field.type, field.isPk),
    }))

    return {
      name: valueNameInputName,
      inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
      getOptionLabel: (option: AutocompleteOptionValueType) => option.label,
      renderAutocompleteOption: (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: { id: string | number; label: string }
      ) => {
        const currentObjectField = objectFields?.find(field => field.name === option.label)
        const isAlreadyUsed = addedFiltersByObject.some(filter =>
          filter.values.some(value => value.name === option.label)
        )

        if (!currentObjectField) {
          return <MenuItem>{option.label}</MenuItem>
        }

        return (
          <MenuItem {...props}>
            {isAlreadyUsed && (
              <DoneAll sx={{ fontSize: '0.9rem', position: 'absolute', left: 2 }} />
            )}
            <Typography ml={1}>
              {getObjectValueName(
                currentObjectField.name,
                currentObjectField.type,
                currentObjectField.isPk
              )}
            </Typography>
          </MenuItem>
        )
      },
      label: t('searchFilterForm.value.label'),
      placeholder: t('searchFilterForm.value.placeholder'),
      isOptionEqualToValue: (option, value) => option.label === value.label,
      disabled: watchedObjectTitle === t('searchFilterForm.object.placeholder'),
      autocompleteOptions: options,
      showTitleFromLabelWithPostfix: true,
      rules: {
        required: true,
      },
    }
  }, [objectFields, valueNameInputName, watchedValueName, watchedObjectTitle, addedFiltersByObject])

  const fieldTypeInput: FormInputProps = {
    name: fieldTypeInputName,
    inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
    getOptionLabel: option => (option.label ? option.label : ''),
    label: t('searchFilterForm.fieldType.label'),
    placeholder: t('searchFilterForm.fieldType.placeholder'),
    isOptionEqualToValue: (option, value) => option.label === value.label,
    autocompleteOptions: Object.values(SEARCH_FIELD_TYPE).map(value => ({
      id: value,
      label: value,
    })),
    rules: {
      required: true,
      validate: value => value !== t('searchFilterForm.fieldType.placeholder'),
    },
  }

  const objectValueInput: FormInputProps = useMemo(
    () => ({
      name: objectValueInputName,
      inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
      getOptionLabel: option => (option.label ? option.label : ''),
      label: t('searchFilterForm.objectValue.label'),
      placeholder: t('searchFilterForm.objectValue.placeholder'),
      isOptionEqualToValue: (option, value) => option.label === value.label,
      autocompleteOptions: linkedObjectFields?.map(field => ({
        id: field.name,
        label: field.name,
      })),
      renderAutocompleteOption: (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: { id: string | number; label: string }
      ) => {
        const isAlreadyUsed = addedFiltersByObject.some(filter =>
          filter.values.some(
            value => value.name === watchedValueName?.label && value.objectValue === option.label
          )
        )

        return (
          <MenuItem {...props}>
            {isAlreadyUsed && (
              <DoneAll sx={{ fontSize: '0.9rem', position: 'absolute', left: 2 }} />
            )}
            <Typography ml={1}>{option.label}</Typography>
          </MenuItem>
        )
      },
      rules: {
        required: true,
        validate: value => value !== t('searchFilterForm.objectValue.placeholder'),
      },
    }),
    [objectValueInputName, linkedObjectFields, addedFiltersByObject, watchedValueName?.label]
  )

  const placeholderValueInput: FormInputProps = useMemo(
    () => ({
      name: placeholderValueInputName,
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      label: t('searchFilterForm.placeholderValue.label'),
      placeholder: t('searchFilterForm.placeholderValue.placeholder'),
      disabled: watchedDefaultplaceholder,
    }),
    [
      linkedObjectFields,
      objectValueInputName,
      defaultPlaceholderInputName,
      placeholderValueInputName,
      watchedDefaultplaceholder,
    ]
  )

  const placeholderStartInput: FormInputProps = useMemo(
    () => ({
      name: placeholderStartInputName,
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      label: t('searchFilterForm.placeholderStart.label'),
      placeholder: t('searchFilterForm.placeholderStart.placeholder'),
      disabled: watchedDefaultplaceholder,
    }),
    [
      linkedObjectFields,
      objectValueInputName,
      defaultPlaceholderInputName,
      placeholderValueInputName,
      watchedDefaultplaceholder,
    ]
  )

  const placeholderEndInput: FormInputProps = useMemo(
    () => ({
      name: placeholderEndInputName,
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      label: t('searchFilterForm.placeholderEnd.label'),
      placeholder: t('searchFilterForm.placeholderEnd.placeholder'),
      disabled: watchedDefaultplaceholder,
    }),
    [
      linkedObjectFields,
      objectValueInputName,
      defaultPlaceholderInputName,
      placeholderValueInputName,
      watchedDefaultplaceholder,
    ]
  )

  const defaultPlaceholderInput: FormInputProps = useMemo(
    () => ({
      name: defaultPlaceholderInputName,
      inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
      label: t('searchFilterForm.defaultPlaceholder.label'),
      labelPlacement: 'end',
    }),
    [
      linkedObjectFields,
      objectValueInputName,
      defaultPlaceholderInputName,
      placeholderValueInputName,
    ]
  )

  const groupRelatedInput: FormInputProps = useMemo(
    () => ({
      name: groupRelatedInputName,
      inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
      label: t('searchFilterForm.groupRelated.label'),
      labelPlacement: 'end',
    }),
    [groupRelatedInputName]
  )

  const dropDownListInput: FormInputProps = useMemo(
    () => ({
      name: dropDownListCodeInputName,
      inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
      placeholder: t('placeholder.dropDownList'),
      label: t('label.dropDownList'),
      autocompleteOptions: dropdownEntityOptions,
    }),
    [dropDownListCodeInputName, dropdownEntityOptions]
  )

  const dropDownWindowInput: FormInputProps = useMemo(
    () => ({
      name: dropDownWindowInputName,
      inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
      placeholder: t('placeholder.dropDownWindow'),
      label: t('label.dropDownWindow'),
      autocompleteOptions: dropdownEntityOptions,
    }),
    [dropDownWindowInputName, dropdownEntityOptions]
  )

  const valueTypeInput: FormInputProps = useMemo(
    () => ({
      name: valueTypeInputName,
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      label: t('label.valueType'),
      placeholder: t('placeholder.valueType'),
      disabled: true,
      value: valueType,
    }),
    [linkedObjectFields, objectValueInputName, valueType]
  )

  const asCheckboxInput: FormInputProps = useMemo(
    () => ({
      name: asCheckboxInputName,
      inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
      label: t('label.asCheckbox'),
      labelPlacement: 'end',
    }),
    [linkedObjectFields, objectValueInputName]
  )

  const asDurationInput: FormInputProps = useMemo(
    () => ({
      name: asDurationInputName,
      inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
      label: t('label.asDuration'),
      labelPlacement: 'end',
    }),
    [linkedObjectFields, objectValueInputName]
  )

  return {
    valueInput,
    valueTypeInput,
    asCheckboxInput,
    asDurationInput,
    fieldTypeInput,
    objectValueInput,
    defaultPlaceholderInput,
    groupRelatedInput,
    placeholderValueInput,
    placeholderStartInput,
    placeholderEndInput,
    dropDownListInput,
    dropDownWindowInput,
  }
}

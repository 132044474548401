import { createContext, FC } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ConfirmModal, FormInput } from '@microservices/wiskey-react-components'
import { DataGrid } from '@microservices/wiskey-react-table-component'
import { Box, Button, Grid, Typography } from '@mui/material'

import { PageContentLayout } from '@layouts/PageContentLayout'

import { GENERATOR_INPUT_TYPE, REGEX } from '@constants'

import { AddDefaultSettingsDialog } from './components/AddDefaultSettingsDialog/AddDefaultSettingsDialog'
import { AddSearchFilterDialog } from './components/AddSearchFilterDialog'
import { useSearchAssistantCreateOrEdit } from './hooks'
import { SearchAssistantsCreateOrEditContextType } from './types'

export const SearchAssistantsCreateOrEditContext =
  createContext<SearchAssistantsCreateOrEditContextType>(
    {} as SearchAssistantsCreateOrEditContextType
  )

export const SearchAssistantsCreateOrEdit: FC = () => {
  const { t } = useTranslation()

  const { state, data, handlers, mutations } = useSearchAssistantCreateOrEdit()

  const {
    showDeleteModal,
    templateTable,
    editCriteria,
    page,
    currentSort,
    showAddSearchFilterModal,
    showDefaultSetting,
    isEditMode,
    isLoadingUpdateVisibility,
  } = state

  const {
    objects,
    isLoading,
    loadingData,
    methods,
    objectsValues,
    columns,
    searchAssistantDataById,
    searchAssistantID,
    modelType,
    countPage,
    isEdit,
    isDirty,
    loadingSearchAssistant,
    loadingAllObjects,
  } = data

  const {
    handleOpenAddSearchFilterModal,
    handleCloseAddSearchFilterModal,
    handleCloseDefaultSettings,
    handleDeleteCriteria,
    handlePage,
    handleSetSorting,
    handleVisibility,
    handleChangeObjectCode,
    handleSetCriteriaTable,
    handleNew,
    handleClose,
    handleDelete,
    handleChangeModelSort,
    handleShowDefaultSettings,
    handleSave,
    handleCancel,
  } = handlers

  const { createOrUpdateCriteria } = mutations

  const contextValue = {
    showAddSearchFilterModal,
    showDefaultSetting,
    openAddSearchFilterModal: handleOpenAddSearchFilterModal,
    closeAddSearchFilterModal: handleCloseAddSearchFilterModal,
    closeDefaultSettings: handleCloseDefaultSettings,
    objects,
    templates: templateTable,
    createOrUpdateCriteria,
    onDelete: handleDeleteCriteria,
    editCriteria,
    isLoadingRow: isLoading,
    countPage: 1,
    onChangePage: handlePage,
    currentPage: page,
    currentSort,
    onSetSorting: handleSetSorting,
    setCriteriaTable: handleSetCriteriaTable,
    onUpdateVisibility: handleVisibility,
    loadingData,
    onChangeObjectCode: handleChangeObjectCode,
    searchAssistantId: searchAssistantDataById?.id,
    contextId: searchAssistantID,
  }

  return (
    <SearchAssistantsCreateOrEditContext.Provider value={contextValue}>
      <PageContentLayout>
        <Box sx={{ width: '100%' }}>
          <FormProvider {...methods}>
            <Box p={1} pb={0}>
              <Grid container sx={{ pb: 2 }}>
                <Typography variant='h5'>{t(`searchAssistants.title.${modelType}`)}</Typography>
              </Grid>
              <FormInput
                disabled={isEdit}
                inputType={GENERATOR_INPUT_TYPE.INPUT}
                label={t(`searchAssistants.label.internalId`)}
                loading={loadingSearchAssistant}
                name='internalId'
                placeholder={t(`searchAssistants.placeholder.internalId`)}
                replacePattern={REGEX.MODEL_CODE_REPLACE_PATTERN}
                size='small'
                sx={{ pt: 1 }}
                rules={{
                  required: true,
                  validate: value => value.trim().length !== 0,
                }}
              />
              <FormInput
                inputType={GENERATOR_INPUT_TYPE.INPUT}
                label={t(`searchAssistants.label.title`)}
                loading={loadingSearchAssistant}
                name='title'
                placeholder={t(`searchAssistants.placeholder.title`)}
                size='small'
                sx={{ pt: 1 }}
                rules={{
                  required: true,
                }}
              />

              <Grid container justifyContent='flex-end'>
                <Box mb={2} mt={2}>
                  <Button
                    disabled={!isDirty}
                    sx={{ mr: '15px' }}
                    variant='contained'
                    onClick={handleSave}
                  >
                    {t('styleSettings.saveButton')}
                  </Button>
                  <Button variant='outlined' onClick={handleCancel}>
                    {t('styleSettings.cancelButton')}
                  </Button>
                </Box>
              </Grid>

              {showAddSearchFilterModal && <AddSearchFilterDialog isEdit={isEditMode} />}
              {showDefaultSetting && <AddDefaultSettingsDialog />}

              <Grid container spacing={1}>
                <Grid item flex={1}>
                  <FormProvider {...methods}>
                    <FormInput
                      autocompleteOptions={objectsValues}
                      inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
                      label={t('label.objectsField')}
                      labelSx={{ mr: 1, width: 130 }}
                      loading={loadingAllObjects}
                      name='objectFilter'
                      placeholder={t('placeholder.objectsField')}
                      size='small'
                    />
                  </FormProvider>
                </Grid>
                <Grid item>
                  <Grid container direction={'column'} spacing={1}>
                    <Grid item>
                      <Button
                        fullWidth
                        disabled={!isEdit}
                        variant='outlined'
                        onClick={handleShowDefaultSettings}
                      >
                        {t('searchAssistant.defaultSetting.button')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        fullWidth
                        disabled={!isEdit}
                        variant={'contained'}
                        onClick={handleNew}
                      >
                        {t('searchAssistant.addBtnText')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {showDeleteModal && (
                <ConfirmModal
                  actionBtnText={t('modal.delete.btn')}
                  containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
                  isShow={showDeleteModal}
                  text={t('modal.delete.warning')}
                  title={t('modal.delete.title')}
                  onClose={handleClose}
                  onConfirm={handleDelete}
                />
              )}
              <Grid container>
                <DataGrid
                  disableMultipleColumnsSorting
                  hideFooter
                  visibility
                  columns={columns}
                  disableColumnResize={false}
                  loading={loadingData}
                  pageCount={countPage}
                  rows={templateTable}
                  sortModel={currentSort}
                  sortingMode={'server'}
                  sx={{ borderRadius: 0 }}
                  onDisableVisibilityCheckbox={() => isLoadingUpdateVisibility}
                  onSortModelChange={handleChangeModelSort}
                  onVisibility={handleVisibility}
                />
              </Grid>
            </Box>
          </FormProvider>
        </Box>
      </PageContentLayout>
    </SearchAssistantsCreateOrEditContext.Provider>
  )
}

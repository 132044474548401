import {
  GETObjectDataParams,
  GETSearchAssistantParams,
  POSTCopySearchAssistantParams,
  POSTSearchAssistantParams,
} from '@types'

import { SearchAssistant } from '../../types/searchAssistant'

import { commonAPI } from './common.api'

export const searchAssistantApi = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchAllSearchAssistants: build.query<SearchAssistant[], GETObjectDataParams>({
      query: params => ({
        url: '/ui/searchAssistant',
        params: {
          searchPattern: params?.searchPattern,
        },
      }),
      providesTags: ['AllSearchAssistants'],
    }),
    fetchSearchAssistantById: build.query<SearchAssistant, GETSearchAssistantParams>({
      query: id => ({
        url: `/ui/searchAssistant/id/${id}`,
        method: 'GET',
      }),
      providesTags: ['SingleSearchAssistant'], // TODO
    }),
    createOrUpdateSearchAssistant: build.mutation<SearchAssistant, POSTSearchAssistantParams>({
      query: searchAssistant => ({
        url: `/ui/searchAssistant`,
        method: 'POST',
        body: searchAssistant,
      }),
      invalidatesTags: ['AllSearchAssistants', 'SearchAssistant', 'SingleSearchAssistant'],
    }),
    copySearchAssistant: build.mutation<SearchAssistant, POSTCopySearchAssistantParams>({
      query: searchAssistant => ({
        url: `/ui/searchAssistant/copy`,
        method: 'POST',
        body: searchAssistant,
      }),
      invalidatesTags: ['AllSearchAssistants', 'SearchAssistant'],
    }),
    deleteSearchAssistant: build.mutation<void, number>({
      query: id => ({
        url: `/ui/searchAssistant/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AllSearchAssistants', 'SearchAssistant'],
    }),
  }),
})

export const {
  useFetchAllSearchAssistantsQuery,
  useFetchSearchAssistantByIdQuery,
  useCreateOrUpdateSearchAssistantMutation,
  useCopySearchAssistantMutation,
  useDeleteSearchAssistantMutation,
} = searchAssistantApi

import { useEffect, useMemo } from 'react'

import {
  useCreateOrUpdateCriteriaMutation,
  useDeleteCriteriaMutation,
  useFetchAllObjectQuery,
  useFetchCriteriaByIdMutation,
  useFetchCriteriasListQuery,
  useUpdateVisibilityByIdMutation,
} from '@redux/api'

import { generateDataGridActionsColumn } from '@helpers'
import { ROUTES, SEARCH_ASSISTANT_TABLE_COLUMNS } from '@constants'

import { useHandlers } from './useHandlers'

export const useSearchAssistantCreateOrEdit = () => {
  const [createOrUpdateCriteria] = useCreateOrUpdateCriteriaMutation()
  const [deleteCriteria] = useDeleteCriteriaMutation()
  const [updateVisibility, { isLoading: isLoadingUpdateVisibility }] =
    useUpdateVisibilityByIdMutation()
  const [fetchCriteria, { data: row, isLoading }] = useFetchCriteriaByIdMutation()
  const { data: objects, isLoading: loadingAllObjects } = useFetchAllObjectQuery()

  const isCreate = ROUTES.CONFIG_SEARCH_ASSISTANTS_CREATE === location.pathname

  const modelType = isCreate ? 'create' : 'edit'

  const objectsValues = useMemo(
    () =>
      objects?.map(obj => ({
        id: obj.code,
        label: obj.title,
      })),
    [objects]
  )

  const { state, handlers, data } = useHandlers({
    fetchCriteria,
    deleteCriteria,
    updateVisibility,
  })

  const { sortParam, objectCodeState } = state

  const {
    id: searchAssistantID,
    isDirty,
    methods,
    searchAssistantDataById,
    isEdit,
    loadingSearchAssistant,
    watchObjectFilter,
  } = data

  const { data: templateData, isLoading: loadingData } = useFetchCriteriasListQuery(
    {
      searchAssistantId: searchAssistantDataById?.id,
      sort: sortParam,
      objectCode: objectCodeState,
    },
    { skip: !searchAssistantDataById, refetchOnMountOrArgChange: true }
  )

  const countPage = templateData?.totalCount || 1

  const {
    handleSetEditRow,
    handleSetCriteriaTable,
    handleChangeObjectCode,
    handleEdit,
    handleClickDelete,
  } = handlers

  useEffect(() => {
    if (row) {
      handleSetEditRow(row)
    }
  }, [row])

  useEffect(() => {
    handleSetCriteriaTable(templateData?.data || [])
  }, [templateData])

  useEffect(() => {
    const objectCode = watchObjectFilter?.id ? `${watchObjectFilter.id}` : null
    handleChangeObjectCode(objectCode)
  }, [watchObjectFilter])

  const columnsWithActions = useMemo(
    () => [
      ...SEARCH_ASSISTANT_TABLE_COLUMNS,
      generateDataGridActionsColumn({
        onEdit: handleEdit,
        onDelete: handleClickDelete,
      }),
    ],
    []
  )

  return {
    data: {
      countPage: 1,
      loadingData: false,
      isLoading,
      objectsValues,
      objects,
      columns: columnsWithActions,
      searchAssistantID,
      searchAssistantDataById,
      modelType,
      methods,
      isEdit,
      isDirty,
      loadingSearchAssistant,
      loadingAllObjects,
    },
    state: {
      ...state,
      isLoadingUpdateVisibility,
    },
    handlers,
    mutations: {
      createOrUpdateCriteria,
    },
  }
}

import { FC, useContext, useMemo } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button } from '@microservices/wiskey-react-components'
import { Add as AddIcon } from '@mui/icons-material'
import { Grid } from '@mui/material'

import { useFetchAllCriteriasQuery } from '@redux/api'

import { SearchAssistantsCreateOrEditContext } from '../../SearchAssistantsCreateOrEdit'

import { getDefaultValues } from './helpers/getDefaultValues'
import { SearchFilterForm } from './AddSearchFilterDialog'
import { ValueInputs } from './ValueInputs'

type SearchFilterValueFormInputsProps = unknown

export const SearchFilterValueFormInputs: FC<SearchFilterValueFormInputsProps> = () => {
  const { control, watch } = useFormContext<SearchFilterForm>()
  const { objects, searchAssistantId } = useContext(SearchAssistantsCreateOrEditContext)
  const {
    fields: searchFilterArrayInputs,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'values',
  })
  const { t } = useTranslation()

  const watchedObjectTitle = watch('objectTitle')
  const objectTitleIsEmpty = watchedObjectTitle === t('searchFilterForm.object.placeholder')

  const disabledAdding = objectTitleIsEmpty
  const disabledRemoving = searchFilterArrayInputs.length <= 1

  const objectCode = useMemo(
    () => (objects || [])?.find(object => object.title === watchedObjectTitle)?.code,
    [objects, watchedObjectTitle]
  )

  const { data: filtersByObjectCode } = useFetchAllCriteriasQuery({ objectCode, searchAssistantId })

  const handleAddButtonClick = () => {
    if (disabledAdding) {
      return
    }
    const { values } = getDefaultValues()
    const emptyValue = values[0]
    append(emptyValue)
  }

  const handleDeleteButtonClick = (index: number) => {
    remove(index)
  }

  return (
    <>
      {searchFilterArrayInputs.map((field, index) => (
        <ValueInputs
          key={field.id}
          addedFiltersByObject={filtersByObjectCode?.data || []}
          {...{ index, disabledRemoving, handleDeleteButtonClick }}
        />
      ))}
      <Grid container>
        <Grid item>
          <Button
            disabled={disabledAdding}
            startIcon={<AddIcon />}
            sx={{ mt: 0.5 }}
            onClick={handleAddButtonClick}
          >
            {t('searchFilterForm.value.addButtonTitle')}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
